<template>
  <AdministrationLayout>
    <template #header>
      <IxRes>administration.roleGroups.title</IxRes>
    </template>
    <RoleGroupsForm />

    <FrsLoadingIndicator :requests="['admin.roles.load']" />
  </AdministrationLayout>
</template>

<script>
import AdministrationLayout from './components/AdministrationLayout'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import RoleGroupsForm from 'src/vue/areas/administration/views/components/role-groups/RoleGroupsForm'

export default {
  components: {
    RoleGroupsForm,
    AdministrationLayout,
    FrsLoadingIndicator
  }
}
</script>

<style lang="scss" scoped>
</style>
