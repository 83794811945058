<template>
  <div class="list-container">
    <div class="search-create-container">
      <Search
        v-model="searchString" :data="groups"
        :keys="searchKeys"
        @result="searchResults = $event"
      />
      <div class="create-group">
        <TextInput v-model="groupName" class="text-field" :placeholder="$i18n.translate('administration.roleGroup.create.name')" />
        <IxButton
          large add
          class="add-button"
          :disabled="!groupName"
          @click="createGroup()"
        />
      </div>
    </div>
    <RecycleScroller
      v-slot="{item}" :items="filteredGroups"
      class="scroller" :item-size="64"
      key-field="id"
    >
      <div :title="groupTitle(item)" @click="toggleSelected(item)">
        <ListCard :selected="item.id === selected.id">
          <template #title>
            {{ item.name }}
          </template>
          <template #subtitle>
            <IxRes>administration.roleGroup.group.description_{{ item.name }}</IxRes>
          </template>
          <template #addon>
            <div :title="$i18n.translate('administration.roleGroup.delete.group')">
              <FontAwesomeIcon :icon="icon" class="icon" @click.stop.prevent="deleteGroup(item.id)" />
            </div>
          </template>
        </ListCard>
      </div>
    </RecycleScroller>
  </div>
</template>

<script>
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {sortBy} from 'lodash'
import {notifications} from 'src/js/infrastructure'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'

import Search from '@components/search/Search'
import ListCard from '@components/card/ListCard'
import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'

export default {
  components: {
    FontAwesomeIcon,
    IxButton,
    TextInput,
    ListCard,
    Search
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['name'],
      searchResults: null,
      groups: [],
      selected: {},
      groupName: null
    }
  },
  computed: {
    filteredGroups () {
      return this.searchResults
        ? this.searchResults
        : this.groups
    },
    icon () {
      return faTimes
    }
  },
  methods: {
    toggleSelected (group) {
      this.selected = this.selected.id === group.id
        ? {}
        : group

      this.$emit('select', this.selected)
    },
    groupTitle (item) {
      if (!(item.hasOwnProperty('roles') && item.roles && item.roles.length)) return []

      return item.roles.map(role => this.$i18n.translate(`administration.roles.name_${role}`)).join(',\n')
    },
    createGroup () {
      try {
        smarterPost('/api/v2/admin/role-groups/add', {name: this.groupName}, {
          id: 'admin.groups.add',
          onResult: (group) => {
            notifications.success(this.$i18n.translate('administration.roleGroup.create.success'))
            this.groupName = null
            this.groups.push(group)
            this.toggleSelected(group)
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.roleGroup.create.error'))
      }
    },
    deleteGroup (id) {
      try {
        smarterPost('/api/v2/admin/role-groups/{id}/delete', null, {
          id: 'admin.groups.add',
          inputs: {
            id: () => id
          },
          onResult: () => {
            notifications.success(this.$i18n.translate('administration.roleGroup.delete.success'))
            if (this.selected.id === id) {
              this.selected = {}
              this.$emit('select', this.selected)
            }
            this.groups = this.groups.filter(group => group.id !== id)
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.roleGroup.delete.error'))
      }
    }
  },
  created () {
    smarterGet('/api/v2/admin/role-groups', {
      id: 'admin.groups.load',
      onResult: (groups) => {
        this.groups = sortBy(groups, 'name')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;

  min-height: 0;
  overflow-y: auto;
  width: 30%;

  .search-create-container {
    display: flex;
    flex-direction: column;

    .create-group {
      display: flex;
      margin-top: 8px;

      .text-field {
        flex: 1;
        margin-right: 2px;
      }
    }
  }

  .icon {
    color: darkgrey;
    font-size: 1.2em;

    &:hover {
      cursor: pointer;
      color: #474747;
    }
  }

  .scroller {
    height: 100%;
    margin-top: 16px;
  }
}
</style>
