<template lang="html">
  <div class="panel list-card" :class="{selected, 'partially-selected': selected === null, [panelClass]: panelClass}">
    <div class="panel-body">
      <div class="list-card-header">
        <div v-if="showAvatar" class="list-card-avatar">
          <slot name="avatar" />
        </div>
        <div class="default-header">
          <span class="title" :title="titleTranslate ? $i18n.translate(title) : title">
            <i v-if="locked" class="fa fa-lock" />

            <IxRes v-if="titleTranslate">{{ title }}</IxRes>
            <slot v-else name="title">
              <span>{{ title }}</span>
            </slot>
          </span>
          <span class="subtitle" :title="subtitle">
            <slot name="subtitle">
              <span v-if="!subtitleTranslate">{{ subtitle }}</span>
              <IxRes v-else>{{ subtitle }}</IxRes>
            </slot>
          </span>
        </div>
        <div class="header">
          <slot name="header" />
        </div>
        <div class="addon">
          <slot name="addon" />
        </div>
      </div>
      <slot name="tags" />
      <slot v-if="showDetails" />
    </div>
    <div v-if="$slots.bottom" class="bottom">
      <slot name="bottom" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    subtitle: String,
    selected: {
      validator: prop => typeof prop === 'boolean' || prop === null
    },
    locked: Boolean,
    showAvatar: Boolean,
    panelClass: {
      default: 'panel-default'
    },
    showDetails: Boolean,
    titleTranslate: Boolean,
    subtitleTranslate: Boolean
  }
}
</script>

<style lang="scss" scoped>
$selectionColor: #eaf0ff;
$stripeColor: lighten($selectionColor, 2%);

.panel {
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:hover {
    border-color: #a0d9ef;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.075);
  }

  transition: all 0.15s;
}

.panel-body {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.panel.selected {
  border-color: #88a3e6;
  background-color: $selectionColor;

  &:hover {
    border-color: darken(#88a3e6, 10%);
  }
}

.panel.partially-selected {
  border-color: #88a3e6;
  background-image: linear-gradient(45deg, transparent 25%, $stripeColor 25%, $stripeColor 50%, transparent 50%, transparent 75%, $stripeColor 75%, $stripeColor 100%);
  background-size: 56.57px 56.57px;

  &:hover {
    border-color: darken(#88a3e6, 10%);
  }
}

.list-card-header {
  display: flex;
}

.list-card-avatar {
  width: 40px;
  height: 40px;
  align-self: center;
  margin-right: 7px;
}

.default-header {
  display: flex;
  flex-direction: column;
  min-width: 0px;
  white-space: nowrap;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  cursor: pointer;

  .title {
    font-size: 1.4em;

    .fa-lock {
      opacity: 0.5;
      margin-right: 0.5em;
    }
  }

  .subtitle {
    font-size: 1em;
    color: darkgray;
  }

  margin-right: auto;
}

.header {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.addon {
  display: flex;
  flex-direction: column;

  &:not(:empty) {
    padding-left: 0.5em;
    // margin-left: auto;
    flex-shrink: 0;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
}
</style>
