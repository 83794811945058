<template>
  <div class="management-container">
    <GroupList @select="selectedGroup = $event" />
    <div class="separator" />
    <RolesList
      :roles="availableRoles" :class="{'opacity': !selectedGroup}"
      @select="assignRole" @hover="hoverRight = $event"
    >
      <template #title>
        <IxRes>administration.roles.title.available</IxRes>
      </template>
    </RolesList>
    <div class="toggle-container" :class="{'opacity': !selectedGroup}">
      <FontAwesomeIcon class="icon" :class="{'on-hover': hoverRight}" :icon="icon.right" />
      <FontAwesomeIcon class="icon" :class="{'on-hover': hoverLeft}" :icon="icon.left" />
    </div>
    <RolesList
      :roles="assignedRoles" :class="{'opacity': !selectedGroup}"
      @select="unassignRole" @hover="hoverLeft = $event"
    >
      <template #title>
        <IxRes>administration.roles.title.assigned</IxRes>
      </template>
    </RolesList>
  </div>
</template>

<script>
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'
import {sortBy, differenceBy} from 'lodash'
import {notifications} from 'src/js/infrastructure'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'

import RolesList from './widgets/RolesList'
import GroupList from './widgets/GroupList'

export default {
  components: {
    GroupList,
    FontAwesomeIcon,
    RolesList
  },
  data () {
    return {
      selectedGroup: null,
      roles: [],
      assignedRoles: [],
      hoverRight: false,
      hoverLeft: false
    }
  },
  computed: {
    availableRoles () {
      return this.assignedRoles.length
        ? differenceBy(this.roles, this.assignedRoles, 'value')
        : this.roles
    },
    icon () {
      return {
        left: faArrowLeft,
        right: faArrowRight
      }
    }
  },
  methods: {
    async assignRole (role) {
      if (!this.selectedGroup) return

      try {
        await smarterPost('/api/v2/admin/role-groups/{groupId}/role/add', [role.value], {
          id: 'admin.roles.assign',
          inputs: {
            groupId: () => this.selectedGroup.id
          },
          onResult: () => {
            this.assignedRoles.push(role)
            notifications.success(this.$i18n.translate('administration.roles.assign.success'))
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.roles.assign.error'))
      }
    },
    async unassignRole (role) {
      try {
        await smarterPost('/api/v2/admin/role-groups/{groupId}/role/remove', [role.value], {
          id: 'admin.roles.unassign',
          inputs: {
            groupId: () => this.selectedGroup.id
          },
          onResult: () => {
            this.assignedRoles = this.assignedRoles.filter(assigned => assigned.value !== role.value)
            notifications.success(this.$i18n.translate('administration.roles.unassign.success'))
          }
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('administration.roles.unassign.error'))
      }
    }
  },
  watch: {
    selectedGroup (value) {
      if (value.hasOwnProperty('roles') && value.roles && value.roles.length) {
        this.assignedRoles = this.selectedGroup.roles.map(role => ({value: role}))
      } else {
        this.assignedRoles = []
      }
    }
  },
  created () {
    smarterGet('/api/v2/admin/roles/all', {
      id: 'admin.roles.load',
      onResult: (roles) => {
        this.roles = sortBy(roles.map(role => ({value: role})), 'value')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.management-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .separator {
    border-left: 1px solid lightgrey;
  }

  .opacity {
    opacity: 30%;
  }

  .toggle-container {
    align-self: center;
    display: flex;
    flex-direction: column;

    .icon {
      font-size: 3em;
      color: lightgrey;
    }
    .on-hover {
      color: darkgrey;
    }
  }
}
</style>
