<template lang="html">
  <div class="layout">
    <h1 v-if="$slots.header">
      <slot name="header" />
    </h1>

    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.layout {
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1em 2em;
}
</style>
